import React from "react";
import Layout from "../components/layout";
import "../styles/contact.css";

const ThankYou = () => (
  <Layout>
    <div className="section-contact">
      <h1 style={{ textAlign: "center", color: "#333333", fontSize: "2rem" }}>Contact</h1>
      <p style={{ textAlign: "center" }}>
        Thank you for your enquiry. RunSeanRun will contact you within 2-3 business days.
      </p>
    </div>
  </Layout>
);

export default ThankYou;
